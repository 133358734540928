
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import '../bootstrap';

import _ from 'lodash';

//Require Sweet Alert for alert
import Swal from 'sweetalert2';
window.Swal = Swal;

//Require Jquery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;
//require('jquery.easing')(window.$);

import 'popper.js'; // Required for BS4
import 'bootstrap';